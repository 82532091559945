.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-box {
    background: #ffffff;
    color: #8a2525 ;
    height: 75%;
    width: 34%;
    border-radius: 12px;
    z-index: 999;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.511);
    overflow: hidden;
  }

  .submit-payment-button {
    /* background-color: ; */
    color: #012e03;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  /* 
  .modal-content {
    padding: 20px;
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #ddd;
    margin-bottom: 20px;
  }
  
  h1 span {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  .modal-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-body h2 {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 10px;
    border: 2px solid #3498db;
    border-radius: 8px;
    color: #3498db;
    position: relative;
  }
  
  .arrow {
    font-size: 24px;
    color: #333;
    position: relative;
    top: 4px;
  }
  
  .modal-body p {
    font-size: 16px;
    color: #666;
    margin: 0;
    padding: 10px;
    border: 2px solid rgb(25, 45, 125);
    border-radius: 8px;
  }
  
  .ticket {
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
  }
  
  .airline-logo {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .form-container {
    width: 70%;
    margin-top: 20px;
    margin-left: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-group {
    width: 100%;
    margin-bottom: 15px;
  }
  
  label {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
    display: block;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  

  
  .submit-button:hover {
    background-color: #45a049;
  } */