@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Futura');



.search-box {
    position: absolute;
    min-width: 33%;
    /* width: 500px; */
    top: 78%;
    right: 58%;
    z-index: 9999;
    min-height: 30px;
    height: auto;
  max-height: 80px;
    background: #fff;
    padding: 5px; 
    border-radius: 20px; 
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); 
    font-family: 'Oswald,Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: all 0.3s ease; 
  }


  .airport-box {
    position: absolute;
    top: 0px;
    right: 35%;
    z-index: 1000;
    min-height: 30px;
    height: 40px;
    width: auto;
  max-height: min-content;
    background:transparent;
    padding: 5px; 
    /* border-radius: 20px;  */
  /* //  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);  */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: all 0.3s ease; 
  }

  .airport-box-button {
    position: absolute;
    top:10%;
    right: 31%;
    z-index: 1000;
    width:12%;
    min-height: 30px;
    height: 40px;
  max-height: fit-content;
    background:white;
    padding: 5px; 
    border-radius: 20px; 
    /* box-shadow: 0 0 15px rgba(11, 116, 7, 0.774);  */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: all 0.3s ease; 
  }
  
  .search-box input {
    width: 70%;
    height: auto;
    padding: 10px; 
    margin-top: 15px;
    margin-right: 0px; 
    border: none;
    border-radius: 10px; 
    font-family: 'Oswald,Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: medium;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
  }
  
  .search-box button {
    font-size: 14px;
    border-radius: 6px;
    width: 80%;
    line-height: 1.5;
    padding: 5px 10px;
    transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
    border: 2px solid #dee1e2;
    /* color: rgb(14, 14, 16); */
    background: #dee1e2;
    display: block;
    height: 36px;
  }
  
  
  .search-box:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  
  .search-box input:hover,
  .search-box input:focus {
    border-color: #1B5879;
    box-shadow: 0 0 10px rgba(0, 55, 158, 0.3);
  }
  
  .search-box button:hover {
    background: #1B5879; 
  }

  /* .background{
    background: url('../assets/background.jpg');
    background-repeat: no-repeat;
    background-size: auto;
  } */
  body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  
  .main-container {
    display: flex;
    flex-direction: column;
    height: 100vh; 
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(234, 234, 234)
    /* background-image: url("https://i.ibb.co/9WtqrYt/pexels-nick-collins-1292998.jpg"); */
  }
  

  .left-side {
    flex: 1;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
  }
  
  .form-container {
    background: rgb(250, 247, 247);
    top: 2%;
    left: 3%;
    height: 80%;
    max-height: 90%;
    position: relative;
    z-index: 900;
    border-radius: 4px;
    margin-top: 20px;
    border-color: #1B5879;
    border: 2px solid #1B5879;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 35%;
    overflow: auto;
    transition: opacity 0.5s ease-in-out;
  
    


    @media (max-width: 768px)  {
      width: 90%;
      left: 5%;
    }

    @media (min-width: 769px) and (max-width:925px) {
      width: 56%;
      left: 5%;
    }
    @media (min-width: 926px) and (max-width:1400px) {
      width: 44%;
      left: 5%;
    }
  }
  
  .content-form-container {
    background: rgb(250, 247, 247);
    top: 2%;
    left: 1%;
    height: 80%;
    max-height: 90%;
    position: relative;
    z-index: 900;
    border-radius: 4px;
    margin-top: 20px;
    border-color: #1B5879;
    border: 2px solid #1B5879;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 58%;
    overflow: auto;
    transition: opacity 0.5s ease-in-out;
  
    @media (max-width: 768px)  {
      width: 0%;
      left: 5%;
    }

    @media (min-width: 769px) and (max-width:925px) {
      width: 0%;
      left: 5%;
    }
    @media (min-width: 926px) and (max-width:1400px) {
      width: 20%;
      left: 5%;
    }
  }
  .footer {
    background-color: #1B5879;
    color: #fff;
    padding: 20px;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.footer a {
    color: #fff;
    text-decoration: none;
}
    /* .fade-out {
      opacity: 0;
    }
     */
  .form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 1px solid #0f0d0d;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
  }
  
  .form-button {
    background: linear-gradient(to bottom right,#005293, #005293);
    border: 0;
    margin-left: 40px;
    width: 57%;
    border-radius: 15px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins',system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
    /* font-style: italic; */
    font-size: 16px;
    font: 700;
    color: #fdfeff;
    font-weight: 250;
    line-height: 2.5;
    outline: transparent;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
  }

  .form-button:not([disabled]):focus {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(35, 112, 228, 0.774), .125rem .125rem 1rem rgba(5, 155, 255, 0.979);
  }
  
  .form-button:not([disabled]):hover {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(35, 112, 228, 0.774), .125rem .125rem 1rem rgba(5, 155, 255, 0.979);
  }
  

  .form-container label {
    display: block;
    margin-bottom: 10px;
  }
  
  .form-container input {
    font-size: 14px;
    border-radius: 6px;
    width: 100%;
    line-height: 1.5;
    border-color: #002366;
    padding: 5px 10px;
    transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
    /* border: 2px solid #1f4f60; */
    /* color: rgb(14, 14, 16); */
    background: #ffffff00;
    height: 36px;
  }
  .form-container input[type="date"]:hover {
    border-color: #cccccc00;
    border: 2px solid #246175;
}
.form-container input:focus{
    border-color:#1B5879;
    background: #fff;
}

  .form-container input[type="date"] {
    width: 80%; 

  }
  
  .side-by-side {
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
    margin-left: 8%;
    margin-top: 5%;
  }
  
  .content-container {
    display: flex;
    height: 100%;
    width: 100%;
  }
  

  #map {
    height: calc(100vh - 64px);
    width: 100%;
  }
  
  .paragraph {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;
    color: #333;
  }




 
  .radio-buttons {
    display: flex;
  }
  
  .radio-button-label {
    display: flex;
    align-items: center;
    margin-right: 32%;
  }
  
  input[type="radio"] {
    margin-right: 8px;
    cursor: pointer;
  }
  
  .radio-button-text {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #051a92;
    cursor: pointer;
  }

  .input-box{
    font-size: 14px;
    display: flex;
    border-radius: 6px;
    width: 40%;
    line-height: 1.5;
    padding: 5px ;
    transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
    border: 2px solid #246175;
    /* color: rgb(14, 14, 16); */
    background: #dee1e2;
    height: 16px;
    

  }